<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3">Lupa Password?</sdHeading>
        <p class="forgot-text">
          Masukkan alamat email Anda dan kami akan melakukan reset password dan mengirimkan password baru ke email Anda
          <!-- Enter the email address you used when you joined and we’ll send you instructions to reset your password. -->
        </p>
        <a-form-item label="Email" name="email">
          <a-input type="email" v-model:value="formState.email" placeholder="Masukkan Email" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Harap Menuggu...' : 'Reset Password' }}
          </sdButton>
        </a-form-item>
        <p class="return-text">Return to <router-link to="/auth/login">Sign In</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive } from 'vue';
import { AuthWrapper } from './style';
import { useStore } from 'vuex';

const ForgotPassword = {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const handleSubmit = values => {
      dispatch('resetPassword', values);
    };

    const formState = reactive({
      email: '',
    });

    return {
      handleSubmit,
      formState,
      isLoading,
    };
  },
};

export default ForgotPassword;
</script>
